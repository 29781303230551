import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, defer } from 'rxjs';
import 'rxjs/add/operator/map';
import { User } from '../_models';
import { License } from '../_models';
import { environment } from '../../environments/environment';
import * as version from "../version";
import { JsonPipe } from '@angular/common';

export function fakeAsyncResponse<T>(data: T) {
  return defer(() => Promise.resolve(data));
}

@Injectable()
export class PortalService {
    private user: any;
    private gatewayUrl: string;
    private x2js: any;
    private licenses: License[];

    constructor(private http: HttpClient) {
      this.gatewayUrl = environment.gatewayUrl + '/go-global/';
      var X2JS = require('node_modules/node-x2js/dist/X2JS.min.js');
      this.x2js = new X2JS();
    }

    activateAccount(key: string)
    {
      return this.http.post(this.gatewayUrl + 'web/activateAccount', "", {
        headers: new HttpHeaders({
          'GG_accountActivationKey': key
        }),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          return true;
        } else {
          throw "Error: " + json.rsp.result;
        }
      });
    }

    createPortalUserAccount(user: User)
    {
      return this.http.post(this.gatewayUrl + 'web/createPortalUserAccount', "", {
        headers: new HttpHeaders({
          'GG_userFirstName' : user.firstname,
          'GG_userLastName' : user.lastname,
          'GG_userName' : user.emailaddress,
          'GG_userPassword' : user.password,
          'GG_ipAddress' : user.ipaddress,
          'GG_isCustomer' : user.isCustomer == true ? "true" : "false",
          'GG_customerInfo' : user.customerInfo
        }),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);

        console.log("response = " + response);
        console.log("%o", json);

        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
            return true;
        } else {
            throw "Error: " + json.rsp.result;
        }
      });
    }

    resendActivationLink(username: string)
    {
      return this.http.post(this.gatewayUrl + 'web/resendActivationLink', "", {
        headers: new HttpHeaders({
          "GG_userName" : username
        }),
        responseType: 'text'
      }).map((response: string) => {
        console.log("resendActivationLink: %o", response);
        var json = this.x2js.xml_str2json(response);
        console.log("resendActivationLink json: %o", json);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          return "true";
        } else if (json != null && json.rsp._stat == 'fail' && json.rsp.result._exception ) {
          return json.rsp.result;
        } else {
          return "false";
        }
      })
    }

    queryAccountActivation(username: string)
    {
      return this.http.post(this.gatewayUrl + 'web/queryAccountActivation', "", {
        headers: new HttpHeaders({
          "GG_userName" : username
        }),
        responseType: 'text'
      }).map((response: string) => {
        console.log("queryAccountActivation: %o", response);
        var json = this.x2js.xml_str2json(response);
        console.log("queryAccountActivation json: %o", json);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          console.log("returning true");
          return "true";
        } else if ( json != null && json.rsp._stat == 'fail' && json.rsp.result._exception ) {
          return json.rsp.result;
        } else {
          console.log("returning false");
          return "false";
        }
      })
    }

    getPortalUser(email: string, password: string) {
        return this.http.post(this.gatewayUrl + 'web/getPortalUser', "", {
                    headers: new HttpHeaders({'GG_userName' : email, 'GG_userPassword' : password}),
                    responseType: 'text'
                  }).map((response: string) => {
                    var json = this.x2js.xml_str2json(response);

                    console.log("response = " + response);
                    console.log("%o", json);

                    if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
                        console.log("login successful");
                        this.user = {};
                        this.user.id = json.rsp.user._id;
                        this.user.token = json.rsp.user;
                        this.user.email = email;
                        [this.user.firstName, this.user.lastName] = json.rsp.user._name.split(" ");
                        // Gateway returns a list of resources this user has access to
                        // no longer returned as part of sign in -- call getGatewayResources() instead
                        // this.user.resources = json.rsp.resources;
                        console.log("resources configured");
                        console.log("%o", this.user);

                        // XxX FIXME - should not be hard coded

                        localStorage.setItem('currentUser', JSON.stringify(this.user));
                      } else {
                        console.log("throwing exception: " + json.rsp.result);
                        this.user = {};
                        if ( json.rsp.result == "null" ) {
                          json.rsp.result = "unknown error, please try again";
                        }
                        throw "Invalid login: " + json.rsp.result;
                      }
                      return this.user;
                  });
    }

    signin(username: string, password: string) {
      return this.http.post(this.gatewayUrl + 'web/api/signin', "", {
        headers: new HttpHeaders({'user': username, 'pass' : password}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);

        console.log("response = " + response + "\n%o", json);

        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
          console.log("login successful");
          this.user = {};
          this.user.id = json.rsp.user._id;
          this.user.token = json.rsp.user;
          this.user.email = username;
          [this.user.firstname, this.user.lastName] = json.rsp.user._name.split(" ");
          console.log("user = %o", this.user);
          localStorage.setItem('currentUser', JSON.stringify(this.user));
        }
        else {
          console.log("signin exception: " + json.rsp.result);
          this.user = {};
          if ( json.rsp.result == "null" ) {
            json.rsp.result = "unknown error, please try again";
          } else if ( json.rsp.result._exception == "org.springframework.security.LockedException" ) {
            if ( json.rsp.result._period == "permanent" ) {
              throw "Your account has been administratively locked. Please contact sales@graphon.com for assistance.";
            }
            throw "Your account has been temporarily locked because an incorrect password was entered too many times.\nYou can try to sign in again in " + json.rsp.result._period + " minutes.";
          }
          throw "Invalid login: " + json.rsp.result;
        }
        return this.user;
      });
    }

    getGatewayResources() {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      console.log('parsed user as %o', user);
      return this.http.post(this.gatewayUrl + 'web/api/getGatewayResources', "", {
        headers: new HttpHeaders({'utok' : user.token.__text}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);
        console.log("resource response = "+response+"\n%o", json);
        user.resources = json.resources;
        localStorage.setItem('currentUser', JSON.stringify(user));
        console.log("storing user with resources: %o", user);
      });
    }

    sendPasswordReset(email: string) {
      return this.http.post(this.gatewayUrl + 'web/sendPasswordReset', "", {
        headers: new HttpHeaders({'GG_userName' : email}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);

        console.log("response = %o", response);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          return true;
        } else {
            throw "Error: " + json.rsp.result;
        }
      });
    }

    updateLicenseDescription(id: string, description: string) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      return this.http.post(this.gatewayUrl + 'web/updateLicenseDescription', "", {
        headers: new HttpHeaders({'GG_userInstanceId' : user.token.__text, 'GG_licenseId' : id, 'GG_description' : description}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);

        console.log("response = %o", response);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success') {
          return true;
        } else {
          throw "Error: " + json.rsp.result;
        }
      });
    }

    updateLicenseUsers(id: string, users: string) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      return this.http.post(this.gatewayUrl + 'web/updateLicenseUsers', "", {
        headers: new HttpHeaders({'GG_userInstanceId' : user.token.__text, 'GG_licenseId' : id, 'GG_quantity' : users}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);
        console.log("updateLicenseUsers response = %o", response);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          return true;
        } else {
          throw "Error: " + json.rsp.result;
        }
      });
    }

    getHostsUsingLicense(id: string) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      return this.http.post(this.gatewayUrl + 'web/getHostsUsingLicense', "", {
        headers: new HttpHeaders({'GG_userInstanceId' : user.token.__text, 'GG_licenseId': id}),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);
        console.log("gethostsusinglicense response: %o", response);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result ) {
          return json.rsp.hosts;
        } else {
          throw "Error: " + json.rsp.result;
        }
      });
    }

    changePassword(activationKey: string, password: string)
    {
      return this.http.post(this.gatewayUrl + 'web/changePassword', "", {
        headers: new HttpHeaders({
          'GG_accountActivationKey' : activationKey,
          'GG_userPassword' : password
          }),
          responseType: 'text'
        }).map((response: string) => {
          var json = this.x2js.xml_str2json(response);
          console.log("response = " + response);
          console.log("rsp = %o", json.rsp);
          if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
            return true;
          } else {
            throw "Error: " + json.rsp.result;
          }
        });
    }

    logout() {
        let user = JSON.parse(localStorage.getItem('currentUser'));
        // remove user from local storage to log user out
        console.log("logging out");
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentAccount');
        localStorage.removeItem('currentProduct');
        localStorage.removeItem('currentLicense');
        localStorage.removeItem('currentHost');
        localStorage.removeItem('currentHardware');
        localStorage.removeItem('currentConfig');
        localStorage.removeItem('currentLicenseName');
        localStorage.removeItem('currentMode');
        localStorage.removeItem('exitOnly');
        localStorage.removeItem('existingLicenseName');
        localStorage.removeItem('currentHeading');
        localStorage.removeItem('currentConnection');
        localStorage.removeItem('currentInstruction');
        localStorage.removeItem('currentConnectionPath');
        localStorage.removeItem('currentWarning');
        localStorage.removeItem('currentUUID');

        if ( user && user.token && user.token.__text ) {
          return this.http.post(this.gatewayUrl + "web/releasePortalUser", "", {
            headers: new HttpHeaders({
              'GG_userInstanceId' : user.token.__text
            }),
            responseType: 'text'
          }).map((response : string) => {
            var json = this.x2js.xml_str2json(response);
            console.log("releasePortalUser response: %o", json.rsp);
          });
        }
    }

	getAccounts() {
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

    return this.http.post(this.gatewayUrl + 'web/getAccounts', "", {
			headers: new HttpHeaders({
			'GG_userInstanceId' : userInstanceID
			}),
      responseType: 'text'
			}).map((response: string) => {
				var json = this.x2js.xml_str2json(response);
				console.log("response = " + response);
				console.log("rsp = %o", json.rsp);
				if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
					//localStorage.setItem('currentAccount', JSON.stringify(json.rsp.accounts));
					//return true;
					//localStorage.setItem('currentAccount', JSON.stringify(json.rsp.accounts.account));
					return json.rsp.accounts;
				} else {
					throw "Error: " + json.rsp.result;
				}
			});
    }

  getCloudLicenseAdminLicenses() {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));

    return this.http.post(this.gatewayUrl + 'web/getCloudLicenseAdminLicenses', "", {
      headers: new HttpHeaders({
        'GG_userInstanceId' : userInfo.token.__text
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp.licenses;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  deactivateHosts(hostIds: number[]) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    let hostIdString = hostIds.join();

    return this.http.post(this.gatewayUrl + 'web/deactivateHosts', "", {
      headers: new HttpHeaders({
        'GG_userInstanceId' : userInfo.token.__text,
        'GG_hostId' : hostIdString
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log ("deactivateHosts rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return true;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  getVersions() {
    return this.http.post('/register/index.php?op=getVersions', "", {responseType: 'text'})
      .map((response: string) => {
        return JSON.parse(response)
      }
    )
  }

	getLicenses() {
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var accountInfo = JSON.parse(localStorage.getItem('currentAccount'));
		var productInfo = JSON.parse(localStorage.getItem('currentProduct'));
		var userInstanceID = undefined;
		var accountID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

		if (accountInfo == null) {
            throw "Error: Account not found";
		}
		else {
			accountID = accountInfo._id;
		}

		if (productInfo == null) {
			productInfo = "GO Global - Windows";
		}

        return this.http.post(this.gatewayUrl + 'web/getLicenses', "", {
			headers: new HttpHeaders({
				'GG_userInstanceId' : userInstanceID,
				'GG_productFamily' : productInfo,
				'GG_accountId' : accountID,
				'GG_hostVersion' : version.version_info.hostVersion}),
        responseType: 'text'
				}).map((response: string) => {
					var json = this.x2js.xml_str2json(response);
          console.log("response = " + response);
					console.log("rsp = %o", json.rsp);
					if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
						/*if (json.rsp.licenses == "") {
							var trialButton = document.getElementById("startTrialButton");

							if (trialButton) {
								trialButton.style.display="block";
							}
						}*/

						return json.rsp.licenses;
					} else {
						throw "Error: " + json.rsp.result;
					}
				});
    }

	getTrialLicenseTemplates() {
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var productInfo = JSON.parse(localStorage.getItem('currentProduct'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

		if (productInfo == null) {
			productInfo = "GO Global - Windows";
		}

        return this.http.post(this.gatewayUrl + 'web/getTrialLicenseTemplates', "", {
			headers: new HttpHeaders({
				'GG_userInstanceId' : userInstanceID,
				'GG_productFamily' : productInfo}),
        responseType: 'text'
				}).map((response: string) => {
					var json = this.x2js.xml_str2json(response);
					console.log("response = " + response);
					console.log("rsp = %o", json.rsp);

					if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
						return json.rsp.licenses;
					} else {
						throw "Error: " + json.rsp.result;
					}
				});
    }

	canActivateLicense(licenseid: string, accountid: string) {
		var uuid = localStorage.getItem('currentUUID');
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

        return this.http.post(this.gatewayUrl + 'web/canActivateLicense', "", {
			headers: new HttpHeaders({
				'GG_userInstanceId' : userInstanceID,
				'GG_licenseId' : licenseid,
				'GG_accountId' : accountid,
				'GG_computerUuid' : uuid,
				'GG_hostVersion' : version.version_info.hostVersion
					}),
          responseType: 'text'
				}).map((response: string) => {
					var json = this.x2js.xml_str2json(response);
					console.log("response = " + response);
					console.log("rsp = %o", json.rsp);
					if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
						return [licenseid, json.rsp];
					} else {
						throw "Error: " + json.rsp.result;
					}
				});
    }

	getLicenseInfo(licenseid: string) {
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

        return this.http.post(this.gatewayUrl + 'web/getLicenseInfo', "", {
			headers: new HttpHeaders({
				'GG_licenseId' : licenseid,
				'GG_userInstanceId' : userInstanceID
				}),
        responseType: 'text'
			}).map((response: string) => {
			var json = this.x2js.xml_str2json(response);
			console.log("response = " + response);
			console.log("rsp = %o", json.rsp);
			if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
				return json.rsp.license;
			} else {
				throw "Error: " + json.rsp.result;
			}
        });
    }

	redeemTrialLicense(licenseid: string, accountid: string, description: string) {
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

        return this.http.post(this.gatewayUrl + 'web/redeemTrialLicense', "", {
			headers: new HttpHeaders({
				'GG_userInstanceId' : userInstanceID,
				'GG_licenseId' : licenseid,
				'GG_accountId' : accountid,
				'GG_description' : description
				}),
        responseType: 'text'
			}).map((response: string) => {
			var json = this.x2js.xml_str2json(response);
			console.log("response = " + response);
			console.log("rsp = %o", json.rsp);
			if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
				localStorage.setItem('currentLicense', json.rsp.id);
				localStorage.setItem('currentLicenseName', json.rsp.name);
				return json.rsp.name;
			} else {
				throw "Error: " + json.rsp.result;
			}
        });
    }

	activateProduct(licenseid: string) {
		var uuid = localStorage.getItem('currentUUID');
		var operatingSystem = localStorage.getItem('currentOS');
		var computerName = localStorage.getItem('currentHost');
		var macAddress = localStorage.getItem('currentHardware');

		var userInfo = JSON.parse(localStorage.getItem('currentUser'));
		var userInstanceID = undefined;

		if (userInfo == null) {
            throw "Error: not logged in";
		}
		else {
			userInstanceID = userInfo.token.__text;
		}

        return this.http.post(this.gatewayUrl + 'web/activateProduct', "", {
			headers: new HttpHeaders({
				'GG_userInstanceId' : userInstanceID,
				'GG_licenseId' : licenseid,
				'GG_computerName' : computerName,
				'GG_macAddress' : macAddress,
				'GG_computerUuid' : uuid,
				'GG_operatingSystem' : operatingSystem,
				'GG_hostVersion' : version.version_info.hostVersion
				}),
        responseType: 'text'
			}).map((response: string) => {
			var json = this.x2js.xml_str2json(response);
			console.log("response = " + response);
			console.log("rsp = %o", json.rsp);
			if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
				return json.rsp.item;
			} else {
				throw "Error: " + json.rsp.result;
			}
        });
    }

  getUserAccountInfo() {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/getUserAccountInfo', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      console.log("rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        userInfo.accountInfo = json.rsp.user;
        localStorage.setItem('currentUser', JSON.stringify(userInfo));
        return json.rsp;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  getEndUsersForPartner(accountid: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(this.gatewayUrl + 'web/getEndUserAccountsForPartner', "", {
      headers: new HttpHeaders({
        'GG_userInstanceId': userInstanceID,
        'GG_accountId': accountid
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      console.log("rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  getLicensesForSupport(account: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(this.gatewayUrl + 'web/getLicensesForSupport', "", {
      headers: new HttpHeaders({
        'GG_userInstanceID': userInstanceID,
        'GG_accountId': account
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      console.log("rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  // stubbed out methods for demo license generation
  getUserTrialStatus() {
    //return fakeAsyncResponse(1);

    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;

    if (userInfo == null) {
      throw "Error: not logged in";
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/getUserTrialStatus', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId' : userInstanceID
        }),
        responseType: 'text'
      }).map((response: string) => {
        var json = this.x2js.xml_str2json(response);
        console.log("response = " + response);
        console.log("rsp = %o", json.rsp);
        if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
          return json.rsp;
        } else {
          throw "Error: " + json.rsp.result;
        }
      });
  }
  validateTrialRecipient(recipient: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw "Error: not logged in";
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/validateTrialRecipient', "",
              {
                headers: new HttpHeaders({
                  'GG_userName': userInfo.email,
                  'GG_userInstanceId' : userInstanceID,
                  'GG_emailAddress': recipient
                }),
                responseType: 'text'
              }).map((response: string) => {
                var json = this.x2js.xml_str2json(response);
                console.log("response = " + response);
                console.log("rsp = %o", json.rsp);
                if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
                  return json.rsp;
                } else {
                  throw "Error: " + json.rsp.result;
                }
              });
  }
  validateHostEligibility(hostid: string, version: string)
  {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw "Error: not logged in";
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/validateHostEligibility', "",
    {
      headers: new HttpHeaders({
        'GG_userInstanceId' : userInstanceID,
        'GG_userName': userInfo.email,
        'GG_hostId' : hostid,
        'GG_version' : version
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw "Error: " +json.rsp.result;
      }
    });
  }
  submitTrialRequest(model: any) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw "Error: not logged in";
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/submitTrialRequest', "",
    {
      headers: new HttpHeaders({
        "GG_version": model.version,
        "GG_licenseType": model.licensetype,
        "GG_deliverTo": encodeURIComponent(model.deliverto),
        "GG_hostname": encodeURIComponent(model.hostname),
        "GG_hostID": encodeURIComponent(model.hostid),
        "GG_description": encodeURIComponent(model.purpose),
        "GG_expiration": model.licensetype=="default" ? "default" : 
              model.expiration.year + '-' + ('0' + model.expiration.month).slice(-2) + '-' + ('0' + model.expiration.day).slice(-2),
        "GG_users": model.users,
        "GG_firstName": encodeURIComponent(model.firstname),
        "GG_lastName": encodeURIComponent(model.lastname),
        "GG_customerInfo": encodeURIComponent(model.endusercompany),
        "GG_country": encodeURIComponent(model.endusercountry),
        'GG_userInstanceId' : userInstanceID,
        'GG_userName' : userInfo.email,
        'GG_approval' : encodeURIComponent(model.approval_reason)
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }
  validateEmail(email: string): Observable<any> {
    return this.http.get('/register/index.php?op=validateEmail&email='+encodeURIComponent(email));
  }
  verifyEmail(email: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/verifyEmail', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_emailAddress': email
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      console.log("response = " + response);
      console.log("rsp = %o", json.rsp);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw "Error: " + json.rsp.result;
      }
    });
  }

  lookupExistingTrial(licenseName: string, email: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/lookupExistingTrial', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_licenseId': licenseName,
        'GG_emailAddress': email
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error: ${response}`;
      }
    });
  }

  lookupExistingLicense(licenseName: string, email: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/lookupExistingLicense', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_licenseId': licenseName,
        'GG_emailAddress': email
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error: ${response}`;
      }
    });
  }

  getAllExperienceNodes() {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text; 
    }
    return this.http.post(this.gatewayUrl + 'web/getAllExperienceNodes', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID
      }),
      responseType: 'text'
    }).map((response: string) => {
      console.log(`all nodes: ${response}`);
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error getting response for all nodes: '${response}'`
      }
    })
  }

  getExperienceNode(nodeid: Number = 0) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }

    return this.http.post(this.gatewayUrl + 'web/getExperienceNode', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_nodeId': String(nodeid)
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error getting response for node ID ${nodeid}: '${response}'`
      }
    })
  }
  getExperienceNodeByKeyword(keyword: string) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(this.gatewayUrl + 'web/getExperienceNodeByKeyword', "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_keywords': keyword
      }),
      responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error getting response for node keyword ${keyword}: '${response}'`;
      }
    })
  }
  addExperienceNode(node: any) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(`${this.gatewayUrl}web/addExperienceNode`, "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_type': node.type,
        'GG_title': node.title,
        'GG_keywords': node.keywords,
        'GG_body': node.body,
        'GG_extras': node.extras
      }), responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error adding node: '${response}'`
      }
    })
  }
  updateExperienceNode(node: any) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(`${this.gatewayUrl}web/updateExperienceNode`, "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_nodeId': node.id,
        'GG_type': node.type,
        'GG_title': node.title,
        'GG_body': node.body,
        'GG_extras': node.extras,
        'GG_keywords': node.keywords,
      }), responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error adding node: '${response}'`;
      }
    })
  }
  deleteExperienceNode(node: any) {
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var userInstanceID = undefined;
    if ( userInfo == null ) {
      throw 'Error: not logged in';
    } else {
      userInstanceID = userInfo.token.__text;
    }
    return this.http.post(`${this.gatewayUrl}web/deleteExperienceNode`, "", {
      headers: new HttpHeaders({
        'GG_userName': userInfo.email,
        'GG_userInstanceId': userInstanceID,
        'GG_nodeId': node.id
      }), responseType: 'text'
    }).map((response: string) => {
      var json = this.x2js.xml_str2json(response);
      if ( json != null && json.rsp && json.rsp._stat == 'ok' && json.rsp.result == 'success' ) {
        return json.rsp;
      } else {
        throw `Error deleting node: '${response}'`;
      }
    })
  }
}

<style scoped>
  .dropzone { 
    margin: 0;
  }
  .dropzone input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .required {
    color:red;
    font-weight: bold;
  }
</style>

<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
      <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Quicksand', Arial, Helvetica, 'Numbus Sans L', sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div *ngIf="model.error != undefined" style="border: 5px solid #ff0000; margin: 25px; padding: 25px;"
  [innerHTML]="model.error">
</div>

<div id="top" style="padding-left: 25px; padding-right:25px;" *ngIf="model.node != undefined">
  <h3>{{ model.node.title }}</h3>
  <div *ngIf="!model.caseNumber" style="padding-bottom: 25px;" [innerHTML]="model.node.body"></div>
  <div *ngIf="model.node.type == 'message'">
    <button *ngIf="model.node.extras != ''" [routerLink]="['/supportform/' + model.node.extras]">Next</button>
  </div>
  <div *ngIf="model.node.type == 'choice'">
    <ng-container *ngFor="let str of model.node.extras.split(',')">
      <button [routerLink]="['/supportform/' + str.split(':')[1]]">{{ str.split(':')[0] }}</button>
    </ng-container>
  </div>
  <div *ngIf="model.node.type == 'dropdown'">
    <select id="dropdown-choice">
      <ng-container *ngFor="let str of model.node.extras.split(',')">
        <option value="{{ str.split(':')[1] }}">{{ str.split(':')[0] }}</option>
      </ng-container>
    </select>
    <div style="padding-top: 25px;">
      <button (click)="navigateToChoice()">Next</button>
    </div>
  </div>
  <!-- final node of the form -->
  <div *ngIf="model.node.type == 'final'">
    <div *ngIf="model.caseNumber">
      <p>
        Thank you for submitting a support case with GraphOn's Technical Support Team.  Your case number is 
        <b>{{ model.caseNumber }}</b>.
      </p>
      <p>
        If you have large files to upload in relation to this case, you can now use case number {{ model.caseNumber }} to upload 
        them to <a href="https://uploads.graphon.com/">uploads.graphon.com</a>.
      </p>
      <p>
        You should receive an auto-response to the email address associated with your GraphOn Portal account shortly.  If you do 
        not see it in your INBOX, please check any Spam or Quarantine folders.
      </p>
      <p>
        Please allow up to 1 business day for a response from GraphOn Support.  The official hours of our Support Team 
        are 7:00 AM through 6:00 PM ET (Eastern Time).
    </div>
    <div *ngIf="!model.caseNumber">
      <div *ngIf="currentUser.accountInfo.account.partner;else directCustomer">
        <p>
          Our records show that your licenses were purchased through {{ currentUser.accountInfo.account.partner.name }}, 
          one of GraphOn’s authorized partners.  As they are your GO-Global support provider, please submit your support request to
          {{ currentUser.accountInfo.account.partner.name }}.
        </p>
      </div>
      <ng-template #directCustomer>
        <form [(ngModel)]="form" #f="ngForm" name="form" (ngSubmit)="submitForm(f)" novalidate autocomplete="off">
          <div class="menu">
            <div class="second-row" style="flex-grow: unset; width: 100%; padding-right: 0; font-weight: 500;">
              <div *ngIf="!f.valid && f.submitted" style="color:#ff0000;">
                <p><b>You Must correct the errors indicated below in red to submit your request.</b></p>
              </div>
              <b>Platform: <span class="required">*</span></b><br/>
              <select id="platform" name="platform" #platform="ngModel" 
                [(ngModel)]="model.platform" required (change)="model.version='';model.hostos=''">
                <option value="" selected disabled> - Select Platform - </option>
                <option *ngFor="let v of model.platforms | keyvalue:reverseKeyOrder" value="{{ v.key }}">{{ v.key }}</option>
              </select>
              <span *ngIf="!platform.valid && (platform.dirty || platform.touched || f.submitted)" style="color:#f00;">
                Please specify the platform to continue.
              </span>
              <br/>
              <div *ngIf="model.platform!=''">
                <br/><b>Version: <span class="required">*</span></b><br/>
                <select id="version" name="version"
                  #version="ngModel" [(ngModel)]="model.version" required (change)="model.hostos=''">
                  <option value="" selected disabled> - Select Version - </option>
                  <option *ngFor="let v of model.platforms[model.platform] | keyvalue:reverseKeyOrder" value="{{ v.key }}">{{ v.value.title }}</option>
                </select>
                <span *ngIf="!version.valid && (version.dirty || version.touched || f.submitted)" style="color:#f00;">
                  Please select a version to continue.
                </span><br/>
              </div>
              <div *ngIf="model.version=='0other'" class="warning">
                <b>Note: </b> <span>
                  This version of GO-Global is not actively supported.  
                  We recommend upgrading to a newer version of GO-Global.
                </span>
              </div>
              <div *ngIf="model.version!=''">
                <br/><b>Host OS: <span class="required">*</span></b><br/>
                <select id="hostos" name="hostos" #hostos="ngModel" [(ngModel)]="model.hostos"
                  required>
                  <option value="" selected disabled> - Choose Host OS - </option>
                  <option *ngFor="let v of model.platforms[model.platform][model.version].hostos | keyvalue:reverseKeyOrder" value="{{ v.key }}">{{ v.value }}</option>
                </select>
                <span *ngIf="model.version!='' && model.hostos=='unknown'" style="color:#f00;">
                  Please select a version to continue.
                </span><br/>
                <div *ngIf="model.hostos=='other'" class="warning">
                  <b>Note: </b><span>
                    GO-Global does not actively support this host OS version.
                    We recommend using a supported host OS.
                  </span>
                </div>
              </div>
              <div *ngIf="( currentUser.accountInfo.account.type.includes('Admin')
                        || currentUser.accountInfo.account.type.includes('Reseller')
                        || currentUser.accountInfo.account.type.includes('Distributor')
                        ) && model.hostos!=''">
                <br/><b>End-User: <span class="required">*</span></b><br/>
                <select id="enduser" name="enduser" #enduser="ngModel" [(ngModel)]="model.enduser"
                  required (change)="updateAvailableLicenses()">
                  <option value="" selected disabled> - Choose End-User - </option>
                  <ng-container *ngFor="let enduser of model.endusers">
                    <option value="{{ enduser.id }}">{{ enduser.name }}</option>
                  </ng-container>
                </select>
                <span *ngIf="(enduser.dirty || enduser.touched || f.submitted) && !enduser.valid" style="color: #f00;">
                  Please select an end-user account to continue.
                </span>
              </div>
              <div *ngIf="(( currentUser.accountInfo.account.type.includes('Admin')
                         || currentUser.accountInfo.account.type.includes('Reseller')
                         || currentUser.accountInfo.account.type.includes('Distributor')) && model.enduser != ''
                        ) || (
                          !( currentUser.accountInfo.account.type.includes('Admin')
                          || currentUser.accountInfo.account.type.includes('Reseller')
                          || currentUser.accountInfo.account.type.includes('Distributor')) &&
                          model.hostos!=''
                        ) ">
                <div *ngIf="model.licenses">
                  <br/><b>License: <span class="required">*</span></b><br/>
                  <select id="license" name="license" #license="ngModel" [(ngModel)]="model.license"
                  required>
                    <option value="" disabled selected> - Choose License - </option>
                    <ng-container *ngFor="let license of model.licenses">
                      <option value="{{ license.id }}">{{ license.name }}</option>
                    </ng-container>
                  </select>
                  <span *ngIf="(license.dirty || license.touched || f.submitted) && !license.valid" style="color: #f00;">
                    Please select a license to continue.
                  </span>
                </div>
                <div *ngIf="model.hostos && !model.licenses && !loading" style="color: #f00;">
                  <b>Error: </b>No active licenses are available for {{ this.currentEndUser }}.<br/>
                  The account must have at least one active license under a support contract
                  to submit a support request.
                </div>
              </div>
              <div *ngIf="model.license != ''">
                <br/><b>Frequency of Issue: <span class="required">*</span></b><br/>
                How often does this issue occur?<br/>
                <select id="frequency" name="frequency" #frequency="ngModel" [(ngModel)]="model.frequency" required>
                  <option value="" selected disabled> - Choose Frequency -</option>
                  <option value="hourly">Multiple times per day</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
                <span *ngIf="(frequency.dirty || frequency.touched || f.submitted) && !frequency.valid" style="color:#f00;">
                  Please select a frequency to continue.
                </span>
              </div>
              <div *ngIf="model.license != ''">
                <br/><b>Workaround: <span class="required">*</span></b><br/>
                Do users currently have a workaround for this issue?<br/>
                <select id="workaround" name="workaround" #workaround="ngModel" [(ngModel)]="model.workaround" required>
                  <option value="" selected disabled> - Choose Value - </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <span *ngIf="(workaround.dirty||workaround.touched||f.submitted) && !workaround.valid" style="color:#f00;">
                  Please select a response to continue.
                </span>
              </div>
              <div *ngIf="model.license!='' && model.workaround=='yes'">
                <br/>
                <textarea name="workarounddescription" id="workarounddescription" 
                  #workarounddescription="ngModel" [(ngModel)]="model.workarounddescription"
                  placeholder="Please briefly explain the current workaround. (*)" required rows="15" cols="80"
                  maxlength="32768"></textarea>
                <div *ngIf="(workarounddescription.dirty||workarounddescription.touched||f.submitted) && !workarounddescription.valid" style="color:#f00;">
                  Please enter a description of the workaround to continue.
                </div>
              </div>
              <div *ngIf="model.license!='' && model.workaround=='no'">
                <br/><b>What percentage of application functionality is blocked by the issue? <span class="required">*</span></b><br/>
                <select id="impact" name="impact" #impact="ngModel" [(ngModel)]="model.impact" required>
                  <option value="" selected disabled> - Choose Value - </option>
                  <option value="10%">10%</option>
                  <option value="20%">20%</option>
                  <option value="30%">30%</option>
                  <option value="40%">40%</option>
                  <option value="50%">50%</option>
                  <option value="60%">60%</option>
                  <option value="70%">70%</option>
                  <option value="80%">80%</option>
                  <option value="90%">90%</option>
                  <option value="100%">100%</option>
                </select>
                <span *ngIf="(impact.dirty||impact.touched||f.submitted) && !impact.valid" style="color: #f00;">
                  Please select a response to continue.
                </span>
              </div>
              <div *ngIf="model.license!=''">
                <br/><b>Are there any specific conditions or actions required to trigger the bug? <span class="required">*</span></b><br/>
                <textarea name="reproduction" id="reproduction" #reproduction="ngModel" [(ngModel)]="model.reproduction" 
                  required="required" cols="80" maxlength="32768"
                  placeholder="Please consider: is this problem specific to OS, user group/type, user, etc.?"></textarea>
                <div *ngIf="(reproduction.touched||reproduction.dirty||f.submitted) && !reproduction.valid" style="color:#f00;">
                  Please describe specific conditions or actions, or enter "None" if there are none.
                </div>
              </div>
              <div *ngIf="model.license != '' && (
                    checkHistoryFor(['display', 'printing', 'session disconnect',
                        'appcontroller', 'web client', 'mobile client'])
                    )">
                <br/><b>Username experiencing problem (for locating relevant logs): </b><br/>
                <input type="text" id="username" name="username" #username="ngModel"
                  [(ngModel)]="model.username">
                <span *ngIf="(username.dirty || username.touched || f.submitted) && !username.valid" style="color: #f00;">
                  Please enter the username of the user that is experiencing the issue to continue.
                </span>
              </div>
              <div *ngIf="model.license!='' && model.frequency != '' && (
                  checkHistoryFor(['display', 'printing', 'session disconnect',
                      'appcontroller', 'web client', 'mobile client'])
              )">
                <br/><b>Timeframe of issue (preferably date and time of day): </b><br/>
                <input type="text" id="timeframe" name="timeframe" #timeframe="ngModel"
                  [(ngModel)]="model.timeframe">
                <span *ngIf="(timeframe.dirty || timeframe.touched || f.submitted) && !timeframe.valid" style="color:#f00;">
                  Please enter the timeframe that the problem occured to continue.
                </span>
              </div>
              <div *ngIf="model.license!='' && checkHistoryFor(['printing'])">
                <br/><b>Printer Name (or 'all' if issue occurs on all printers): <span class="required">*</span></b><br/>
                <input type="text" id="printer" name="printer" #printer="ngModel"
                  [(ngModel)]="model.printer" required>
              </div>
              <div *ngIf="model.license!='' && checkHistoryFor(['web client'])">
                <br/><b>Browser: <span class="required">*</span></b><br/>
                <select id="browser" name="browser" #browser="ngModel" [(ngModel)]="model.browser"
                  required>
                  <option value="" selected disabled> - Choose Browser - </option>
                  <option value="Mozilla Firefox">Mozilla Firefox</option>
                  <option value="Apple Safari">Apple Safari</option>
                  <option value="Google Chrome">Google Chrome</option>
                  <option value="Microsoft Edge">Microsoft Edge</option>
                  <option value="Other">Other</option>
                </select>
                <span *ngIf="(browser.dirty || browser.touched || f.submitted) && !browser.valid" style="color:#f00">
                  Please select a browser to continue.
                </span>
                <div *ngIf="model.license!='' && model.browser == 'Apple Safari'" style="color:#f00;">
                  <b>Note: </b>GO-Global is only compatible with version 12 and later on MacOS.
                </div>
                <div *ngIf="model.license!='' && model.browser == 'Other'" style="color:#f00;">
                  <b>Warning: </b>No other browsers are supported with the GO-Global web client.
                  You can still submit a support request but GraphOn cannot guarantee a fix will
                  be provided.
                </div>
              </div>
              <div *ngIf="model.license!='' && checkHistoryFor(['mobile client'])">
                <br/><b>OS: <span class="required">*</span></b><br/>
                <select id="os" name="os" #os="ngModel" [(ngModel)]="model.os" required>
                  <option value="" selected disabled> - Choose Mobile OS - </option>
                  <option value="iOS">iOS</option>
                  <option value="Android">Android</option>
                </select>
                <span *ngIf="(os.dirty || os.touched || f.submitted) && !os.valid" style="color:#f00;">
                  Please select a mobile operating system to continue.
                </span>
              </div>
              <div *ngIf="model.license!='' && model.os=='iOS'">
                <br/><b>iOS Version: <span class="required">*</span></b><br/>
                <select id="iosversion" name="iosversion" #iosversion="ngModel" [(ngModel)]="model.iosversion" required>
                  <option value="" selected disabled> - Choose iOS version - </option>
                  <option value="iOS 17">iOS 17</option>
                  <option value="iPadOS 17">iPadOS 17</option>
                  <option value="iOS 16">iOS 16</option>
                  <option value="iPadOS 16">iPadOS 16</option>
                  <option value="iOS 15">iOS 15</option>
                  <option value="iPadOS 15">iPadOS 15</option>
                  <option value="iOS 14">iOS 14</option>
                  <option value="iPadOS 14">iPadOS 14</option>
                  <option value="iOS 13">iOS 13</option>
                  <option value="iPadOS 13">iPadOS 13</option>
                  <option value="iOS 12">iOS 12</option>
                </select>
                <span *ngIf="(iosversion.dirty || iosversion.touched || f.submitted) && !iosversion.valid" style="color:#f00;">
                  Please select an iOS version to continue.
                </span>
              </div>
              <div *ngIf="model.license!='' && model.os=='Android'">
                <br/><b>Android version: <span class="required">*</span></b><br/>
                <select id="androidversion" name="androidversion" #androidversion="ngModel"
                  [(ngModel)]="model.androidversion" required>
                  <option value="" disabled> - Choose Android Version - </option>
                  <option value="15">Android 15</option>
                  <option value="14">Android 14</option>
                  <option value="13">Android 13</option>
                  <option value="12L">Android 12L</option>
                  <option value="12">Android 12</option>
                  <option value="11">Android 11</option>
                  <option value="10">Android 10</option>
                  <option value="9">Android 9</option>
                </select> 
                <span *ngIf="(androidversion.dirty || androidversion.touched || f.submitted) && !androidversion.valid" style="color:#f00;">
                  Please select an Android Version to continue.
                </span>
              </div>
              <div *ngIf="model.license!=''">
                <br/><b>Summary: <span class="required">*</span></b><br/>
                <input type="text" id="summary" name="summary" #summary="ngModel" size="80" maxlength="255"
                  [(ngModel)]="model.summary" required placeholder="Please enter a brief description of the issue." />
                <br/>
                <span *ngIf="(summary.dirty || summary.touched || f.submitted) && !summary.valid" style="color: #f00;">
                  Please enter a summary of the issue to continue.
                </span>
              </div>
              <div *ngIf="model.license!=''">
                <br/><b>Issue: <span class="required">*</span></b><br/>
                <textarea name="issue" id="issue" #issue="ngModel" [(ngModel)]="model.issue"
                  placeholder="Describe your issue here." required rows="15" cols="80">
                </textarea>
                <div *ngIf="(issue.dirty || issue.touched || f.submitted) && !issue.valid" style="color:#f00;">
                  Please describe the issue to continue.
                </div>
              </div>
              <div *ngIf="model.license!=''">
                <br/><b>Emails to CC: </b><br/>
                <textarea name="ccs" id="ccs" #ccs="ngModel" [(ngModel)]="model.ccs"
                  placeholder="List any e-mail addresses to CC on correspondence, one per line."
                  rows="5" cols="80"></textarea>
              </div>
              <div *ngIf="model.license!=''">
                <br/><b>Attachments: </b><br/>
                <p>Please ensure all necessary files are attached.  The vast majority of support cases will require a 
                  .ZIP file of logs from the GO-Global Support Request Wizard, which can be run on the host (from the
                  GO-Global Admin Console > Help > Support Request Wizard).</p>
                <p>You may also include any other files (images, videos, specific log files, etc.) that you think may
                  be helpful for the GraphOn Support Team.  Any large files (such as videos or memory dumps) should be
                  compressed to a .ZIP file before uploading.
                </p>
                <p>Examples of additional files that might be relevant for this support case include:</p>
                <ul>
                  <li>Support Request Wizard (.zip)</li>
                  <li *ngIf="checkHistoryFor(['display'])">Image/video of the issue (.jpg, .png, .mp4, etc.)</li>
                  <li *ngIf="checkHistoryFor(['apphang'])">Memory Dump (.dmp)</li>
                  <li *ngIf="checkHistoryFor(['gghang'])">Belarc Report (.html or .pdf)</li>
                  <li *ngIf="checkHistoryFor(['systemhang'])">Kernel-mode memory dump (.dmp)</li>
                  <li *ngIf="checkHistoryFor(['performance'])">Image/video of the issue (.jpg, .png, .mp4, etc.)</li>
                </ul>
                <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
                  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                  <i class="fas fa-upload"></i>
                  <h3>Drag and drop file(s) here</h3>
                  <h3>or</h3>
                  <label for="fileDropRef">Browse for file</label>
                </div>
                <div class="files-list">
                  <div class="single-file" *ngFor="let file of model.files; let i = index">
                    <i class="fas fa-regular fa-file-export"></i>
                    <span class="upload-info">
                      <span class="upload-name">
                        {{ file?.name }}
                      </span>
                      <span class="upload-size">
                        {{ formatBytes(file?.size) }}
                      </span>
                      <span *ngIf="file.progress==100" style="cursor: pointer" (click)="deleteFile(i)"><i class="fas fa-trash-alt"></i></span>
                      <app-progress [progress]="file?.progress" [status]="file?.status"></app-progress>
                      <span *ngIf="file.error" style="max-width: 75%; white-space: normal;">
                        {{ file.error }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="model.license!='' && model.issue!='' && model.files.length == 0">
                <input type="checkbox" id="bypassfiles" name="bypassfiles" #bypassfiles="ngModel"
                  [(ngModel)]="model.bypassfiles">
                While nearly every support request requires the information contained in the the support request wizard package, if, 
                for some reason, you are not able to attach any files, click this checkbox to enable the support request to be submitted
                without attachments.
              </div>
              <div *ngIf="model.license!='' && f.valid && (model.files.length > 0 || model.bypassfiles)">
                <br/><input style="height: 42px !important; font-size: 16px; padding: 0 30px !important;" 
                  type="submit" name="submit" value="Submit Support Request" (click)="window.scrollTo(0,0)"/>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </div> <!-- caseNumber not assigned -->
  </div> <!-- model.node.type is final -->
</div> <!-- model.node is undefined -->
  
<div style="display:none;">
  <pre style="background:#f0f000; border: solid 1px #800000; white-space: pre-wrap; font-size: 10px; text-align: left;">
{{ this.diagnostics }}
  </pre>
</div>
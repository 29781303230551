import { Component, OnInit, Renderer2, NgZone, ElementRef, ViewChild, NgModule } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormControl } from '@angular/forms'
import { AlertService, PortalService } from '../_services/index'
import { CookieService } from 'ngx-cookie-service'
import { License, Host } from '../_models'

import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service'
import { time } from 'console'
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http'

import { environment } from '../../environments/environment';
import { KeyValue } from '@angular/common'

@Component({
    moduleId: module.id,
    templateUrl: 'supportForm.component.html'
})

export class SupportFormComponent implements OnInit {
  reverseKeyOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return b.key.localeCompare(a.key);
  }
  reverseValueOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return b.value.localeCompare(a.value);
  }

  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  model: any = {
    nodeId: 328349139,
    nodeHistory: [],
    version: '',
    platform: '',
    hostos: '',
    enduser: '',
    license: '',
    frequency: '',
    workaround: '',
    impact: '',
    browser: '',
    os: '',
    iosversion: '',
    androidversion: '',
    issue: '',
    summary: '',
    files: [],
    platforms: {},
  }
  currentUser: any = {}
  loading: boolean = false
  gatewayUrl: string;
  
  constructor(
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private portalService: PortalService,
    private http:HttpClient,
    private alertService:AlertService,
  )
  {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    console.log("read user: %o", this.currentUser);
    this.model.currentUser = this.currentUser;
    this.gatewayUrl = environment.gatewayUrl;
    let msie = /msie\s|trident\//i.test(window.navigator.userAgent)
    if ( msie) {
      this.router.navigate(["/unsupported"])
    }
  }

  ngOnInit() {
    this.model.files = [];
    this.route.params.subscribe((params) => {
      this.loading = true;
      this.portalService.getVersions().subscribe(data => {
        console.log("versions got: %o", data);
        this.model.platforms = data.versions;
        this.model.hostoses = data.hostos;
        this.portalService.getEndUsersForPartner(this.currentUser.id).subscribe(data => {
          console.log("getEndUsers: %o", data);
          this.model.endusers = data.accounts.account;
          this.portalService.getLicensesForSupport(this.currentUser.accountInfo.id).subscribe(data => {
            console.log("getLicenses: %o", data);
            this.model.licenses = data.licenses.license;
            this.updateAvailableLicenses();
            this.model.nodeId = params['node'];
            if ( typeof this.model.nodeId == 'undefined' ) {
              this.portalService.getExperienceNodeByKeyword('start').subscribe(data => {
                console.log("getStartNode: %o", data);
                this.model.nodeId = data.node.id;
                this.navigateToNode(this.model.nodeId);
              })
            } else {
              this.navigateToNode(this.model.nodeId);
            }
          }) // get licenses for support
        }) // get end users for partner
      }); // get product versions
    }); // route params subscription
  }

  navigateToChoice() {
    this.router.navigate(['/supportform/' + ((document.getElementById('dropdown-choice') as HTMLFormElement).value)]);
  }

  navigateToNode(nodeString: string) {
    let nodeId : number = parseInt(nodeString);
    this.loading = true
    try {
      console.log(`navigating to node ${nodeString} (${nodeId})`);
      this.portalService.getExperienceNode(nodeId).subscribe(data => {
        console.log("getExperienceNode() got data = %o", data)
        this.model.nodeId = nodeId;
        this.model.node = data.node;
        this.model.node.extras = this.model.node.extras.replace('\n','');
        this.loading = false;
        this.model.error = undefined;
        this.model.nodeHistory.push({ id : nodeId, keywords: data.node.keywords, title: data.node.title });
      }, (error) => {
        this.model.error = error;
        this.loading = false;
      });
    } catch (error) {
      console.log("navigateToNode failed: " + error)  
    }
  }

  updateAvailableLicenses() {
    this.loading = true;
    let account : string = this.model.enduser ? this.model.enduser : this.currentUser.accountInfo.account.id;
    this.portalService.getLicensesForSupport(account).subscribe(data => {
      if ( data.licenses == "" ) {
        this.model.licenses = undefined;
        this.model.license = '';
      } else {
        if ( !Array.isArray(data.licenses.license) ) {
          data.licenses.license = [data.licenses.license];
        }
        this.model.licenses = data.licenses.license;
        this.model.license = '';
      }
      this.loading = false;
    })
  }

  checkHistoryFor(str:string[]): boolean {
    return this.model.nodeHistory.some((n:any) => {
      return str.some((s:string) => n.keywords.includes(s));
    });
  }

  get diagnostics() { return JSON.stringify(this.model, null, 2) +
    JSON.stringify(this.currentUser, null, 2); }

  get currentEndUser() {
    let ret:string = "(unknown account)";
    if ( this.model.enduser ) {
      this.model.endusers.forEach((acct: any) => {
        if ( acct.id == this.model.enduser ) {
          ret = acct.name;
        }
      })
    } else {
      ret = this.currentUser.accountInfo.account.name;
    }
    return ret;
  }

  onFileDropped($event: any[]) {
    console.log("file dropped");
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files: any[]) {
    console.log("file browsed");
    this.prepareFilesList(files);
  }
  deleteFile(index: number) {
    if ( this.model.files[index].progress < 100 ) {
      return;
    }
    var formdata = new FormData();
    formdata.set('file', this.model.files[index].url);
    this.http.post(this.gatewayUrl + '/files/del.php', formdata, {
      observe: 'events'
    }).subscribe((event: HttpEvent<any>) => {
      console.log('delete request got response: %o', event);
    })
    this.model.files.splice(index,1);
  }
  prepareFilesList(files: Array<any>) {
    for(const item of files) {
      if ( item.size > 16 * 1024 * 1024 ) {
        item.progress = 100;
        item.status = "#d98989";
        item.error = 'File size is ' + this.formatBytes(item.size) + '; maximum upload size is 16MB.';
        this.model.files.push(item);
        continue;
      }
      item.progress = 0;
      item.status = 'initializing';
      item.formdata = new FormData();
      item.formdata.append('file', item, item.name);
      console.log("uploading %o", item);
      this.http.post(this.gatewayUrl+'/files/', item.formdata, {
        reportProgress: true,
        observe: 'events'
      }).subscribe((event: HttpEvent<any>) => {
        console.log('upload got event: %o', event);
        switch(event.type) {
          case HttpEventType.Sent:
            item.status = '#303030';
            item.progress = 0;
            break;
          case HttpEventType.Response:
            if ( event.body.status == 'ERROR' ) {
              item.status = '#d98989';
              item.progress = 100;
              item.error = event.body.error;
            } else {
              item.status = '#50dc54';
              item.progress = 100;
              item.url = event.body.url;
            }
            break;
          case HttpEventType.UploadProgress:
            item.status = '#4c97cb';
            item.progress = Math.round(event.loaded / event.total * 10000)/100;
            break;
        }
      },
      error => {
        console.log('upload error: %o', error);
        item.status = '#d98989';
        item.progress = 100;
        item.error = error.error.text || error.message;
      })
      this.model.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
  }

  formatBytes(bytes: number, decimals = 2) {
    if ( bytes == 0 ) {
      return "0 bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes)/Math.log(k));
    return parseFloat((bytes / Math.pow(k,i)).toFixed(dm)) + " " + sizes[i];
  }

  submitForm(formData: any) {
    this.alertService.clear();
    window.scroll({
      top:0,
      left:0,
      behavior: 'instant'
    });
    console.log("formData: %o", formData);
    if ( !formData.valid ) {
      console.log("invalid form data, not submitting");
      return;
    }
    // replacer helper for JSON.stringify to include undefined values
    const includeUndefined = (_key: any, value: any) => typeof value === 'undefined' ? '' : value;

    let httpFormData = new FormData();
    httpFormData.append('op', 'submitSupportRequest');
    httpFormData.append('supportRequest', JSON.stringify(this.model, includeUndefined));
    this.loading = true;
    this.http.post(this.gatewayUrl + "/register/index.php", httpFormData)
      .subscribe( res => {
      this.loading = false;
      console.log("support request submit response: %o", res);
      if ( res['status'] == "success" ) { 
        this.model.caseNumber = res['caseNumber'].replace(/^0+/, "");
        document.getElementById("top").scrollIntoView(true);
      } else {
        this.alertService.error("Error: " + res['message'] + ". Please try again later.");
      }
    }, err => {
      this.loading = false;
      console.log("support request submit error: %o", err);
      this.alertService.error("Error: " + err.message + ".  Please try again later.");
    })
  }
}

